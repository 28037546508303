<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">直付通管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" v-if="this.sa_admin.role == 2" size="mini" @click="add()">添加服务商</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" size="mini">
        <el-table-column label="id" prop="id" width="50px"> </el-table-column>
        <el-table-column label="直付通商户名" prop="mchName" width="200px">
        </el-table-column>
        <el-table-column label="应用appId" prop="appid" width="150px"></el-table-column>
        <el-table-column label="类型" prop="type" width="150px">
          <template slot-scope="s">
            <b style="color: green; margin-left: 10px" v-if="s.row.type == 1">直付通服务商</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.type == 2">直连商户</b>
            <b style="color: green; margin-left: 10px" v-if="s.row.type == 3">扫码授权</b>
            <b style="color: blue; margin-left: 10px" v-if="s.row.type == 4">第三方应用</b>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="150px">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <ServerAdd ref="alipay-server-add"></ServerAdd>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ServerAdd from "./alipay-server-add";

export default {
  components: { ServerAdd },
  data() {
    return {
      p: {
        page: 1,
        limit: 10,
        type: [1],
        payType: [0],
      },
      title: "添加服务商",
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/alipay/config/query",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    // 删除
    del: function (data) {
      var defaultCfg = {
        type: 'delete', //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/alipay/config/delete/" + data.id,
        function () {
          this.f5();
        }.bind(this), defaultCfg
      );
    },
    add: function () {
      this.$refs["alipay-server-add"].open(0, 1);
    },
    update: function (data) {
      this.$refs["alipay-server-add"].open(data, 1);
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
